<template>
  <div>
    <nav-bar header-title="团课战队" :show-back="true" :header-back="newAppBack" />
    <div class="page-content">
      <div class="city-btn" @click="cityShow = true">
        {{ cityText }} <van-icon name="play" />
      </div>
      <!-- <div class="city-btn">福州市</div> -->
      <img :src="baseImgUrl + 'rule-btn.png?v=1'" class="rule-btn" alt="" @click="ruleShow = true">
      <img :src="baseImgUrl + 'gift-btn.png'" class="gift-btn" alt="" @click="giftShow = true">
      <div>
        <p class="time">{{ total.effectMonth }}</p>
        <div class="total">
          <div @click="goList">
            <p>我的本月团课课量</p>
            <p>{{ total.lessonNum || 0 }}节<van-icon name="arrow" /></p>
          </div>
          <div class="record" @click="goRecord">
            我的组队记录 <van-icon name="arrow" class="ml6" />
          </div>
        </div>
        <div class="task-box" v-if="isNowMonth && nowTask.title">
          <p class="task" @click="getTask(true)">
            <span class="row-start-center"><span>{{ nowTask.title }}</span><span class="status">进行中</span></span>
            <span class="rtime">{{ nowTask.weekOfTask }}</span>
          </p>
          <van-icon name="arrow" />
        </div>
        <div class="tab" :class="'tabImg' + act">
          <span @click="changeTabOrMonth">
            {{ mText }}战队排行榜<van-icon name="play" />
          </span>
          <span @click="getMyTeam">我的战队</span>
        </div>
        <div class="list-content">
          <div v-show="act == 1" class="rank">
            <div class="my-rank" v-if="hasTeam1">
              <span class="mc">我</span>
              <span class="avt mr24">
                <img :src="myTeam1.teamLogo" alt="">
              </span>
              <span class="pub_onefull black mr10">
                <p class="f28 ellipsis-1">{{ myTeam1.teamName }}</p>
                <p class="f22 fw4 mt10 row-start-center" style="color: #3C454E;" v-if="myTeam1.callTeacher"
                  @click="gotoCoachDetail(myTeam1.callTeacher.id)">
                  为<span class="ellipsis-1">{{ myTeam1.callTeacher.name }}</span>
                  <span style="width: 60px;flex-shrink: 0;">教练打call</span>
                  <van-icon name="arrow" class="mt1" />
                </p>
              </span>
              <span class="mr10 tc">
                <p class="mt2">{{ myTeam1.rank }}</p>
                <p class="f22 fw4 mt10">排名</p>
              </span>
              <span class="tc">
                <p class="mt2">{{ myTeam1.teamLessonNum }}</p>
                <p class="f22 fw4 mt10">课量</p>
              </span>
            </div>
            <div class="my-rank not-in" v-else>
              <span>{{ isNowMonth ? '我暂未加入战队' : '当月未加入战队' }}</span>
              <span v-show="isNowMonth && actStatus" class="creat-btn" @click="saveTeam('create')">马上创建</span>
            </div>
            <div v-for="(i, index) in teamRankList" :key="index" class="rank-item">
              <span class="mc">
                <img :src="baseImgUrl + (index + 1) + '.png'" alt="" v-if="index < 3">
                <span v-else>{{ index + 1 }}</span>
              </span>
              <span class="avt mr24">
                <img :src="i.teamLogo" alt="">
              </span>
              <span class="pub_onefull mr20">
                <p class="f28 fw5 ellipsis-1 ">{{ i.teamName }}</p>
                <p class="f22 fw4 mt10 row-start-center" style="color: #3C454E;" v-if="i.callTeacher"
                  @click="gotoCoachDetail(i.callTeacher.id)">
                  为<span class="ellipsis-1">{{ i.callTeacher.name }}</span>
                  <span style="width: 60px;flex-shrink: 0;">教练打call</span>
                  <van-icon name="arrow" class="mt1" />
                </p>
              </span>
              <span><span class="f34 fw5">{{ i.teamLessonNum }}</span>节</span>
            </div>
          </div>
          <div v-show="act == 2" class="my-team">
            <div class="t-detail" v-if="hasTeam2">
              <p class="t-icon">
                <span @click="saveTeam('change')" v-if="myTeam2.teamOwnerFlag">
                  <img :src="baseImgUrl + 'edit2.png'" alt="">
                  修改资料
                </span>
                <span @click="qTeam('解散')" v-if="myTeam2.teamOwnerFlag">
                  <img :src="baseImgUrl + 'break2.png'" alt="">
                  解散战队
                </span>
                <span @click="qTeam('退出')" v-if="!myTeam2.teamOwnerFlag">
                  <img :src="baseImgUrl + 'quit2.png'" alt="">
                  退出战队
                </span>
              </p>
              <div :class="{ 'call-box': hasCall }">
                <div class="my-rank pl22 mt15">
                  <span class="avt mr24">
                    <img :src="myTeam2.teamLogo" alt="">
                  </span>
                  <span class="pub_onefull black mr10">
                    <p class="ellipsis-1">{{ myTeam2.teamName }}</p>
                    <p class="f22 fw4 mt10">{{ myTeam2.teamMembers }}人</p>
                  </span>
                  <span class="mr30 tc">
                    <p class="mt2">{{ myTeam2.rank }}</p>
                    <p class="f22 fw4 mt10">排名</p>
                  </span>
                  <span class="tc">
                    <p class="mt2">{{ myTeam2.teamLessonNum }}</p>
                    <p class="f22 fw4 mt10">课量</p>
                  </span>
                </div>
                <div class="call" v-if="myTeam2.callTeacher">
                  <img class="icon" :src="baseImgUrl + 'call.png'" alt="">
                  正在为
                  <img class="avt" :src="myTeam2.callTeacher.pic" alt="">
                  <span class="mr5 ellipsis-1" style="color:#F24C2A;max-width: 140px;">{{ myTeam2.callTeacher.name
                  }}</span>
                  教练打call
                </div>
              </div>
              <div class="team-user">
                <div v-for="(i, index) in 5" :key="index">
                  <div class="tag">
                    <img v-if="index < userInfoList.length && userInfoList[index].ownerFlag"
                      :src="baseImgUrl + 'leader.png'" alt="">
                    <img
                      v-if="index < userInfoList.length && userInfoList[index].newUserFlag && !userInfoList[index].ownerFlag"
                      :src="baseImgUrl + 'new.png'" alt="">
                  </div>
                  <div class="user-box">
                    <div class="user" v-if="index < userInfoList.length">
                      <img :src="userInfoList[index].headImg" alt="">
                      <span class="count">{{ userInfoList[index].lessonNum }}节</span>
                    </div>
                    <div v-else class="user-empty" @click="inviteFun('icon')">
                      <van-icon name="plus" />
                    </div>
                  </div>
                  <p v-if="index < userInfoList.length" class="name ellipsis-1">{{ userInfoList[index].nickName }}</p>
                </div>
              </div>
              <div class="add-record" v-if="myTeam2.teamOwnerFlag" @click="showApply">
                <p class="zero" v-if="applyJoinCount == 0">组队申请记录 <van-icon name="arrow" /></p>
                <p class="has-user" v-else><span class="num">{{ applyJoinCount }}</span>个好友申请加入战队<van-icon name="arrow" />
                </p>
              </div>
              <div class="btn-box">
                <div class="call-btn" :class="{ 'mr18': userInfoList.length != 10 }" v-if="myTeam2.teamOwnerFlag"
                  @click="tShow = true">打call教练
                </div>
                <div class="invite" v-if="userInfoList.length != 10" @click="inviteFun('btn')">邀请好友组队</div>
              </div>
            </div>
            <div class="empty-box" v-else>
              <img :src="baseImgUrl + 'empty.png'" alt="">
              <p class="mt20">本月暂未加入战队</p>
              <p class="mt10">快去加入战队赢好礼吧</p>
              <p class="c-btn" v-if="isNowMonth && actStatus" @click="saveTeam('create')">创建我的战队</p>
            </div>
          </div>
        </div>
      </div>
      <img :src="baseImgUrl + 'go-gift.png?v=1'" class="go-gift" alt="" @click="goGift">
      <img :src="baseImgUrl + 'top24-10.png'" class="top" alt="" v-if="topShow" @click="toTOP">
      <!-- <img :src="baseImgUrl + 'newIn.png'" class="new-user" alt="" @click="toNew"> -->
    </div>
    <!-- 规则 -->
    <van-popup v-model="ruleShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius close"
      :close-icon="baseImgUrl + 'close2.png'">
      <p class="rule-title">活动规则</p>
      <div class="rule-box">
        {{ rule }}
      </div>
    </van-popup>
    <!-- 奖励 -->
    <van-popup v-model="giftShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius close"
      :close-icon="baseImgUrl + 'close2.png'">
      <p class="rule-title">活动奖励</p>
      <div class="gift-box">
        <!-- <img class="cfj" :src="baseImgUrl + 'cfj.jpg'" alt=""> -->
        <img class="gift" :src="baseImgUrl + 'gift24-10.png?v=3'" alt="">
      </div>
    </van-popup>
    <!-- 月份 -->
    <van-popup v-model="mShow" safe-area-inset-bottom position="bottom" class="pop-radius">
      <van-picker title="月份选择" show-toolbar :columns="mList" @confirm="monthConfirm" @cancel="mShow = false"
        :default-index="mIndex" />
    </van-popup>
    <!-- 城市 -->
    <van-popup v-model="cityShow" safe-area-inset-bottom position="bottom" class="pop-radius">
      <van-picker title="城市选择" show-toolbar :columns="cityList" @confirm="cityConfirm" @cancel="cityShow = false"
        :default-index="cityIndex" />
    </van-popup>
    <!-- 创建和修改 -->
    <van-popup v-model="cShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius c-box box-title"
      @close="cancelFun">
      <p class="title">{{ type == 'create' ? '创建战队' : '修改资料' }}</p>
      <img class="avt" :src="type == 'create' ? 'https://img.chaolu.com.cn/APP/images/logo.png' : myTeam2.teamLogo"
        alt="">
      <p class="f26 tl mb10 ml60">战队名</p>
      <div>
        <van-field class="input" :class="{ focus: appType() == 'and' }" v-model.trim="teamName" placeholder="为你的战队取个名字吧"
          show-word-limit maxlength="12" />
      </div>
      <div class="create-btn" @click="saveFun">
        <p>{{ type == 'create' ? '创建' : '保存' }}</p>
      </div>
    </van-popup>
    <!-- 打call教练 -->
    <van-popup v-model="tShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius t-box box-title"
      @close="tShow = false; callTeacher = myTeam2.callTeacher">
      <p class="title">打call教练</p>
      <div class="line no-chose" v-if="!callTeacher" @click="choseTeacher">
        <span>请输入教练姓名</span>
        <img class="t-icon" :src="baseImgUrl + 'tIcon.png'" alt="">
      </div>
      <div class="line chose" v-else>
        <span @click="choseTeacher" class="left">
          <img class="avt" :src="callTeacher.pic" alt="">
          <span class="ellipsis-1">{{ callTeacher.name }}</span>
          <img class="change" :src="baseImgUrl + 'change24-10.png'" alt="">
        </span>
        <img class="close" :src="baseImgUrl + 'close2.png'" alt="" @click="dShow = true">
      </div>
      <p class="f24 mt20 pl70 mb120" style="color: #3C454E;">支持3次设置，25日后无法修改</p>
      <div class="create-btn" @click="saveCallTeacher">
        <p>保存</p>
      </div>
    </van-popup>
    <!-- 删除教练二次确认 -->
    <van-popup v-model="dShow" class="q-box no-anim">
      <p class="f36 fw6 black">确认删除教练</p>
      <p class="mt30">确定要删除“{{ callTeacher ? callTeacher.name : '' }}”教练吗？</p>
      <p class="mt10">每月25日之后无法修改教练</p>
      <div class="q-btn">
        <span @click="dShow = false">我再想想</span>
        <span class="btn1" @click="callTeacher = undefined; dShow = false">确定</span>
      </div>
    </van-popup>
    <!-- 加入战队申请 -->
    <van-popup v-model="aShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius a-box box-title"
      @close="tShow = false; callTeacher = myTeam2.callTeacher">
      <p class="title">好友加入战队申请</p>
      <div class="list">
        <div class="item" v-for="i in aList">
          <img class="avt" :src="i.userHeadImg" alt="">
          <p class="content">
            <span class="name ellipsis-1">{{ i.userNickName }}</span>
            <span class="text">{{ i.applyTime }} 申请加入战队</span>
            <span class="status" v-if="i.joinStatus == 0"><van-count-down :time="i.waitFinishSecond * 1000"
                format="HH:mm:ss" class="time" />&nbsp;后失效</span>
            <span class="c1" v-else-if="i.joinStatus == 1"> <img :src="baseImgUrl + 'ok.png'" alt=""> 已同意入队</span>
            <span class="c2" v-else>{{ i.failMsg }}</span>
          </p>
          <p class="btn" v-if="i.joinStatus == 0" @click="agreeFun(i)">同意</p>
        </div>
        <div class="empty-box" v-if="aList.length == 0">
          <img :src="baseImgUrl + 'empty.png'" alt="">
          <p class="mt20">暂无好友申请记录</p>
        </div>
      </div>
    </van-popup>
    <!-- 解散和退出 -->
    <van-popup v-model="qShow" class="q-box no-anim">
      <p class="f36 fw6 black">{{ qType }}战队</p>
      <p class="mt30">确定要{{ qType }}您的“{{ myTeam2.teamName }}”吗？</p>
      <p class="mt10">每个月仅限{{ qType }}一次战队。</p>
      <div class="q-btn">
        <span @click="qShow = false">取消</span>
        <span class="btn1" @click="qTeamFun">确定</span>
      </div>
    </van-popup>
    <!-- 加入战队 -->
    <van-popup v-model="jShow" class="j-box no-anim" :class="jionBtnType == 'WAIT' ? 'hei' : ''"
      :close-on-click-overlay="false">
      <img :src="baseImgUrl + 'join.png'" alt="" class="title">
      <img class="avt" :src="addTeam.teamLogo" alt="">
      <p class="f30 fw6">{{ addTeam.teamName }}</p>
      <p class="f24 mt30" style="padding: 0px 20px;">“{{ addTeam.userName }}”邀请您加入TA的战队！</p>
      <p class="f24 mt10">（每月仅可退出一次战队）</p>
      <p v-if="jionBtnType == 'ENABLE_JOIN'" class="jion-btn" @click="joinFun">申请加入Ta的战队</p>
      <template v-else>
        <p class="jion-btn dis">等待队长通过即可入队</p>
        <p class="tips"><van-count-down :time="waitFinishSecond" format="HH:mm:ss" class="time" />后队长未同意则组队申请失效</p>
      </template>
      <img :src="baseImgUrl + 'close.png'" alt="" class="close" @click="jShow = false">
    </van-popup>
    <!-- 加入失败弹窗 -->
    <van-popup v-model="lShow" class="j-box no-anim" :close-on-click-overlay="false">
      <img :src="baseImgUrl + addTeam.teamStatus + '.png'" alt="" class="title">
      <img class="avt" :src="addTeam.teamLogo" alt="">
      <p class="f30 fw6">{{ addTeam.teamName }}</p>
      <p class="f24 mt30">{{ lText }}</p>
      <p class="jion-btn mt30" @click="lShow = false">我知道了</p>
      <img :src="baseImgUrl + 'close.png'" alt="" class="close" @click="lShow = false">
    </van-popup>
    <!-- 结算 -->
    <van-popup v-model="fShow" class="f-box no-anim">
      <p class="f32 fw6">月度结算</p>
      <p class="t-detail">
        <img class="avt" :src="fData.teamLogo" alt="">
        <span class="pub_onefull f28 ellipsis-2 tl">{{ fData.teamName }}</span>
        <span class="mc">{{ fData.rank }} <span class="f22">名</span> </span>
      </p>
      <img :src="baseImgUrl + 'gx.png'" alt="" class="gx">
      <p class="f26">战队累计课量{{ fData.lessonNum }}节，获得第{{ fData.rank }}名！</p>
      <div class="detail">
        <p class="title">队员课量明细</p>
        <div class="d-box">
          <p v-for="i in fList" class="detail-item">
            <img class="avt" :src="i.headImg" alt="">
            <span class="pub_onefull tl ellipsis-1">{{ i.nickName }}</span>
            <span class="ml15">{{ i.lessonNum }}节</span>
          </p>
        </div>
      </div>
      <p class="f-btn" @click="fShow = false">我知道了</p>
    </van-popup>
    <!-- 打卡任务 -->
    <van-popup v-model="taskShow" safe-area-inset-bottom position="bottom" closeable class="pop-radius task-pop"
      @close="taskShow = false">
      <p class="title">周周新挑战</p>
      <p class="tips">完成每周任务，本周结束后赠送1次团课量</p>
      <div class="list">
        <div class="item" v-for="(i, index) in taskList">
          <p class="content">
            <span class="l1">
              <span>{{ i.weekOfTask }}</span>
              <span class="status" v-if="i.inProgress == 1">进行中</span>
            </span>
            <span>{{ i.title }}（{{ i.finishNum }}/{{ i.taskObjectiveNum }}）<van-icon class="icon" name="warning-o"
                @click="showTips(index)" /></span>
          </p>
          <span class="btn" :class="i.taskStatus != 1 ? 'not' : ''" @click="finshTask(i, index)">{{
            taskStatus[i.taskStatus] }}</span>
        </div>
      </div>
    </van-popup>
    <!-- 任务说明 -->
    <van-popup v-model="tipsShow" class="r-radius">
      <div class="r-info-pop">
        <p class="ri-title">任务说明</p>
        <p class="ri-content">{{ tipsText[tipIndex] }}</p>
        <p class="ri-content mt10" v-if="tipIndex == 1">
          动态类团课：莱美经典、自研发课、燃脂、塑形、舞蹈、户外飞盘六大类之中任意课程；<br />静态类团课：瑜伽、普拉提、身心平衡三大类之中任意课程；
        </p>
        <div class="btn" @click="tipsShow = false">我知道了</div>
      </div>
    </van-popup>
    <!-- 分享 -->
    <common-share :value="showShare" :shareParams="shareParams" @close="showShare = false"
      @share-success="showShare = false" :shareItem="['minifriend', 'postermessage', 'save']"
      :miniSharePoster="appTypeStr === 'mini'" :reload="true">
      <template slot="createDom">
        <div class="canvascss">
          <img :src="baseImgUrl + 'sharebg24-10.png'" class="canvascss_bg" />
          <div class="canvascss_left">
            <img :src="userDetail.headImg" />
          </div>
          <div class="canvascss_name ellipsis">
            {{ userDetail.nickName }}
          </div>
          <div class="canvascss_right">
            <img v-if="userDetail.qrCode" :src="'data:image/png;base64,' + userDetail.qrCode" />
          </div>
        </div>
      </template>
    </common-share>
    <!-- 选择教练 -->
    <ChooseTeacher @setShareTeacher="setShareTeacher" ref="refChooseTeacher" />
  </div>
</template>

<script>
import { newAppBack, initBack, appType, hideAppBackBtn, gotoCoachDetail, gotoAppPage } from '@/lib/appMethod';
import navBar from '@/components/nav-bar-202210/index'
import userMixin from '@/mixin/userMixin';
import commonShare from "@/components/commonShare/newShare";
import ChooseTeacher from './chooseTeacher.vue';
import { getLastMonth, getMonthRange } from '@/lib/utils';
import wx from "weixin-js-sdk";

export default {
  components: {
    navBar,
    commonShare,
    ChooseTeacher,
  },
  mixins: [userMixin],
  data() {
    return {
      baseImgUrl: 'https://img.chaolu.com.cn/ACT/teamWarband-202308/',
      // 分享相关
      showShare: false,
      shareItem: ["minifriend"],
      shareParams: {
        title: "戳我组队赢大礼",
        miniImage: "https://img.chaolu.com.cn/ACT/teamWarband-202308/mini24-10.png",
        path: "/pages/webView/index?webUrl=" + window.location.origin + "/#/superdeer-activity/teamWarband-202308",
        miniProgramType: window.location.origin.indexOf('web.chaolu.com') > -1 ? '0' : '2', // 正式0，开发1，体验2
        url: "url",
        multiple: "0.95",
        userName: "gh_7f5b57b6a76e",
      },
      userDetail: {},
      act: 1,
      topShow: false,
      ruleShow: false,
      giftShow: false,
      mList: [],
      mShow: false,
      mText: '',
      mValue: '',

      cShow: false,
      type: 'change',
      teamName: '',
      qShow: false,
      qType: 'break',
      jShow: false,
      fShow: false,
      total: {},
      teamRankList: [],
      isNowMonth: true,
      hasTeam1: true,
      hasTeam2: false,
      myTeam1: {}, //切换tab的时候。重新获取,如果没有重新获取。会导致数据不对
      myTeam2: {},//切换tab的时候。重新获取,如果没有重新获取。会导致数据不对
      userInfoList: [],
      addTeam: {},
      fData: {},
      fList: [],
      tag: 0,
      cityList: [],
      cityShow: false,
      cityText: '',
      cityIndex: 0,
      cityId2: '',
      lShow: false,
      lText: '',
      mIndex: 0,

      // 9-25新增
      hasCall: true,
      tShow: false,
      dShow: false,
      callTeacher: undefined,
      jionBtnType: '',
      waitFinishSecond: 0,
      applyJoinCount: 0,
      aShow: false,
      aList: [],
      rule: `
活动城市：福州、厦门、广州
    活动时间：自然月为一轮活动周期进行团课课量榜单数据统计。
    战队说明:
    1、会员可自行组建战队也可接受好友邀请加入他人战队；
    2、每个战队最多5人，超过后无法加入；
    3、战队课量统计，该战队所有会员，在该月的团课课量，如战队内有超鹿团课新用户，则新用户首节团课按5节计算；
    4、战队将会每周发布任务，其分为团队赛及个人赛；完成战队每周发布任务，则获得额外对应奖励；
    5、会员加入战队方式:作为队长创建战队邀请好友加或接受他人邀请加入战队；
    6、会员每个月仅能在15号前主动退出一次战队，15号后不可退出战队；
    7、加入战队时需要队长通过申请才可加入该战队（180分钟内需要队长同意申请才可正常进入）；
    8、打call教练当月仅可修改三次，25日后无法修改；
    9、创建及加入战队截止时间：当月26日0点；
    10、若战队课量相同，则按创建战队的时间顺序先后排序，先创建的战队排名在前；
    11、若活动期间，战队队长解散该战队，则战队团队任务所获得的奖励记录将移除；
    
    奖励规则
    奖励发放时间：
      获奖实物券于活动结束后5个工作日内发放；国庆限定徽章实物券于12月5日发放到超鹿个人账户；
      团队赛任务奖励于10月8日进行统一结算，赠送课量至队长，本周结束后，任务进度不作累加，固化；
      个人赛任务奖励于任务结束后当天发放，将直接加入个人课量内；
    数据统计：本月活动仅限参与团课城市（其它城市团课不计入总量），奖励及打卡进度每月1号00:00刷新，本月团课上课数量不会累计到次月。
    1.自然月内，根据战队内成员团课上课数量总计，战队课量排名前10名，战队内全员可得奖励。
    2.自然月内，未在前十战队，战队总课量达80节（含）以上，战队内全体成员可得奖励。
    3.自然月内，未在前十战队且当月打卡大于30节的用户可得奖励`,
      // 24-9-25打卡任务
      taskShow: false,
      taskList: [],
      nowTask: {},
      taskStatus: ['未开始', '去完成', '已完成', '已结束'],
      tipsShow: false,
      tipIndex: 0,
      tipsText: [
        '本次任务为团队赛，战队所有会员在任务时间内打卡团课总量≥15节，即可获得本周奖励；奖励课量将于10月8日赠送至战队队长课量内；若队长解散该战队，则奖励课量记录会移除；本周结束后，任务进度不作累加，固化。',
        '任务说明：本周任务为个人赛，会员在任务时间内完成动态类团课+静态类团课组合课程1组，即可获得本周奖励；奖励课量将于10月15日赠送至个人课量内。',
        '本周任务为个人赛，会员在任务时间内完成塑形类任意课程，即可获得本周奖励；奖励课量将于10月22日赠送至个人课量内。',
        '本周任务为个人赛，会员在任务时间内完成当周周五任意一门团课课程，即可获得本周奖励（免费团课除外）；奖励课量将于10月29日赠送至个人课量内。',
        '本周任务为个人赛，会员在任务时间内连续3天打卡团课，即可获得本周奖励；奖励课量将于11月1日赠送至个人课量内。'
      ],
      // 10-29新增
      actStatus: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.scrallFun)
  },
  async created() {
    initBack()
    hideAppBackBtn()
    await this.$getAllInfo(['userId', 'cityId'])
    this.mList = getLastMonth(getMonthRange(new Date('2024-09-01'), new Date()) + 1)
    if (sessionStorage.getItem('tw-202308')) {
      const { mValue, cityId } = JSON.parse(sessionStorage.getItem('tw-202308'))
      this.cityId2 = cityId
      this.mValue = mValue
      this.mList.map((item, index) => {
        if (item.value == mValue) {
          this.monthConfirm(item)
          this.mIndex = index
        }
      })
    } else {
      this.cityId2 = this.cityId
      this.monthConfirm(this.mList[0])
    }
    // 城市相关
    // this.$axios.post(this.baseURLApp + '/venue/scheduleLessonCityList').then(res => {
    //   res.data.map((item, index) => {
    //     this.cityList.push({
    //       text: item.areaName,
    //       value: item.cityId
    //     })
    //     if (item.cityId == this.cityId2) {
    //       this.cityText = item.areaName
    //       this.cityIndex = index
    //     }
    //   })
    // })
    this.cityList = [
      { text: '福州', value: '3' },
      { text: '厦门', value: '036652995ac5451fbb6791c3491e1c2d' },
      { text: '广州', value: '818447834079563776' },
      // { text: '小鹿', value: '953954026917990400' },
    ]
    this.cityList.map((item, index) => {
      if (item.value == this.cityId2) {
        this.cityText = item.text
        this.cityIndex = index
      }
    })
    // 结算
    this.settlementFun()
    this.hasInvite()
    window.scrollTo(0, 0)
    const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/teamWarband-202308`)
    this.shareParams.path = `/pages/webView/index?webUrl=${uu}`
    wx.miniProgram.postMessage({
      data: {
        type: "share",
        shareImage: this.shareParams.miniImage,
        shareTitle: this.shareParams.title,
        shareUrl: this.shareParams.path,
      },
    });
    // 活动状态
    this.$axios.post(this.baseURLApp + '/schedule/teamActivity/status', {}).then((res) => {
      if (res.code == 1) {
        this.actStatus = res.data
      }
    })
  },
  watch: {
    'myTeam2.teamId': {
      handler: function (val, oldVal) {
        if (val !== oldVal && val) {
          this.initQr()
          const uu = encodeURIComponent(`${window.location.origin}/#/superdeer-activity/teamWarband-202308`)
          this.shareParams.path = `/pages/webView/index?webUrl=${uu}` + '&teamId=' + this.myTeam2.teamId
        }
      },
      deep: true
    }
  },
  methods: {
    newAppBack,
    appType,
    gotoCoachDetail,
    scrallFun() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.topShow = scrollTop > 600 && this.act == 1
    },
    toTOP() {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    goList() {
      sessionStorage.setItem('tw-202308', JSON.stringify({
        mValue: this.mValue,
        cityId: this.cityId2
      }))
      this.$router.push('/superdeer-activity/teamWarband-202308/list?mValue=' + this.mValue + '&cityId=' + this.cityId2);
    },
    goRecord() {
      sessionStorage.setItem('tw-202308', JSON.stringify({
        mValue: this.mValue,
        cityId: this.cityId2
      }))
      this.$router.push('/superdeer-activity/teamWarband-202308/myRecord?mValue=' + this.mValue + '&cityId=' + this.cityId2);
    },
    toNew() {
      this.$router.push('/superdeer-activity/inviteGroupNewUser-202311');
    },
    tagFun() {
      if (this.tag == 0) {
        this.tag = 3
        let timer = setInterval(() => {
          this.tag--
          if (this.tag == 0) {
            clearInterval(timer)
          }
        }, 1000)
        return true
      } else {
        return false
      }
    },
    changeTabOrMonth() {
      if (this.act == 1) {
        this.mShow = true
      } else {
        this.act = 1
        this.getRank()
      }
    },
    monthConfirm(m) {
      this.mText = m.text1
      this.mValue = m.value
      this.mShow = false
      this.getRank()
    },
    cityConfirm(c) {
      this.cityText = c.text
      this.cityId2 = c.value
      this.cityShow = false
      this.init()
      this.settlementFun()
      if (this.act == 1) {
        this.getRank()
      } else {
        this.getMyTeam()
      }
    },
    saveTeam(type) {
      this.type = type
      this.cShow = true
      this.oldName = this.teamName
    },
    saveFun() {
      if (this.teamName.length < 1) {
        this.$toast('请输入战队名称')
        return
      }
      if (/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|\u25fb|\u25fc|\ud83c[\udd0c-\udd1a]|\ud83c[\udd30-\udd39]|\ud83c[\udd27-\udd29])/g.test(this.teamName)) {
        this.$toast('战队名不能有Emoji！');
        return
      }
      if (!this.tagFun()) {
        return
      }
      if (this.type == 'create') { //创建
        this.$axios.post(this.baseURLApp + '/schedule/teamActivity/createTeam', {
          userId: this.userId,
          cityId: this.cityId2,
          teamName: this.teamName
        }).then((res) => {
          if (res.code == 1) {
            this.$toast('创建成功')
            this.cShow = false
            this.init()
            if (this.act == 1) {
              this.getRank()
            } else {
              this.teamName = ''
              this.getMyTeam()
            }
          }
        })
      } else { //修改
        this.$axios.post(this.baseURLApp + '/schedule/teamActivity/modifyTeam', {
          userId: this.userId,
          teamName: this.teamName,
          teamId: this.myTeam2.teamId
        }).then((res) => {
          if (res.code == 1) {
            this.$toast('修改成功')
            this.cShow = false
            this.getMyTeam()
          }
        })
      }
    },
    cancelFun() {
      this.cShow = false
      this.teamName = this.oldName
    },
    qTeam(type) {
      this.qType = type
      this.qShow = true
    },
    qTeamFun() {
      if (!this.tagFun()) {
        return
      }
      let url = this.qType == '解散' ? '/schedule/teamActivity/disbandTeam' : '/schedule/teamActivity/exitTeam'
      this.$axios.post(this.baseURLApp + url, {
        userId: this.userId,
        teamId: this.myTeam2.teamId
      }).then((res) => {
        if (res.code == 1) {
          this.$toast(this.qType + '成功')
          this.qShow = false
          this.getMyTeam()
          this.teamName = ''
        }
      })
      this.countPoint('63', '333', this.qType == '解散' ? '1634' : '1635')
    },
    async joinFun() {
      try {
        await this.$axios.post(this.baseURLApp + '/schedule/teamActivity/applyJoinTeam', {
          userId: this.userId,
          teamId: this.addTeam.teamId
        }).then((res) => {
          this.cityList.map(item => {
            if (item.value == this.addTeam.cityId) {
              this.cityText = item.text
              this.cityId2 = item.value
            }
          })
          this.jionBtnType = 'WAIT'
          this.waitFinishSecond = res.data * 1000
          this.getRank()
          this.init()
        })
      } catch (error) {
        this.jShow = false
        this.getRank()
        this.init()
      }
    },
    initQr() {
      this.$axios.post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
        token: this.token,
        userId: this.userId,
        width: 240,
        height: 240,
        webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
        scene: JSON.stringify({
          source: `teamWarband-202308`,
          teamId: this.myTeam2.teamId,
          userId: this.userId,
          webUrl: `${window.location.origin}/#/superdeer-activity/teamWarband-202308`
        }),
      }).then((res) => {
        this.userDetail = res.data;
      });
    },
    init() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/index', {
        userId: this.userId,
        cityId: this.cityId2
      }).then((res) => {
        this.total = res.data
        this.getTask()
      })
    },
    getRank() {
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/showTeamRank', {
        userId: this.userId,
        cityId: this.cityId2,
        effectMonth: this.mValue
      }).then((res) => {
        this.teamRankList = res.data.teamRankList
        this.hasTeam1 = res.data.userTeamRank.teamName ? true : false
        this.myTeam1 = res.data.userTeamRank
        this.isNowMonth = this.mValue == this.mList[0].value
      })
    },
    getMyTeam() {
      this.act = 2
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/showTeamInfo', {
        userId: this.userId,
        cityId: this.cityId2,
      }).then((res) => {
        this.myTeam2 = res.data
        this.hasTeam2 = res.data.teamName ? true : false
        this.isNowMonth ? this.hasTeam1 = true : ''
        if (this.hasTeam2) {
          this.userInfoList = res.data.userInfoList
          this.teamName = this.myTeam2 ? res.data.teamName : ''
          this.callTeacher = res.data.callTeacher
          this.applyJoinCount = res.data.applyJoinCount || 0
        } else {
          this.teamName = ''
          this.userInfoList = []
        }
      })
    },
    inviteFun(type) {
      this.showShare = true
      this.countPoint('63', '333', type == 'icon' ? '1632' : '1633')
    },
    hasInvite() { // 邀请
      // 避免页面调回来的时候。再次弹出
      if (this.$route.query.teamId && !sessionStorage.getItem('hasIn')) {
        sessionStorage.setItem('hasIn', true)
        if (!this.userId) {
          this.$store.commit('openLoginPopup', true)
          return
        }
        this.$axios.post(this.baseURLApp + '/schedule/teamActivity/acceptInvitation', {
          userId: this.userId,
          teamId: this.$route.query.teamId
        }).then((res) => {
          if (res.code == 1) {
            this.addTeam = res.data
            if (res.data.teamStatus == 'ENABLE_JOIN' || res.data.teamStatus == 'WAIT') {
              this.jShow = true
              this.jionBtnType = res.data.teamStatus
              this.waitFinishSecond = res.data.waitFinishSecond * 1000
            } else {
              this.lShow = true
              let msg = {
                DISBAND: '战队已解散，请您加入其他战队！',
                FULL: '战队已满员，请您加入其他战队！',
                HISTORY: '无法加入历史月份战队，请您加入其他战队！',
              }
              this.lText = msg[res.data.teamStatus]
              this.init()
            }
          }
        })
        this.countPoint('63', '333', '1631')
      } else {
        this.init()
        this.countPoint('63', '333', '1630')
      }
    },
    settlementFun() {
      // 结算弹窗
      if (!this.userId) {
        this.$store.commit('openLoginPopup', true)
        return
      }
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/settlement', {
        userId: this.userId,
        cityId: this.cityId2,
      }).then((res) => {
        if (res.data.remindFlag) {
          this.fData = res.data
          this.fList = res.data.teamUserList
          this.fShow = true
        }
      })
    },
    // 9-25新增
    choseTeacher() {
      this.$refs.refChooseTeacher.show = true;
    },
    setShareTeacher(tObj) {
      this.callTeacher = tObj
    },
    saveCallTeacher() {
      let oType = Boolean(this.myTeam2.callTeacher), nType = Boolean(this.callTeacher), type = ''
      type = oType ? (nType ? 'change' : 'del') : (nType ? 'add' : '')
      if (type == '') {
        this.tShow = false
      } else {
        let fun = { add: '/schedule/teamActivity/addCallTeacher', change: '/schedule/teamActivity/modifyCallTeacher', del: '/schedule/teamActivity/removeCallTeacher' }
        let data = {
          userId: this.userId,
          teamId: this.myTeam2.teamId
        }
        if (type == 'add' || type == 'change') {
          data.teacherId = this.callTeacher.id
        }
        this.$axios.post(this.baseURLApp + fun[type], data).then((res) => {
          if (res.code == 1) {
            this.$toast(type == 'add' ? '设置成功' : (type == 'change' ? '修改成功' : '删除成功'))
            this.tShow = false
            this.getMyTeam()
          }
        })
      }
    },
    showApply() {
      this.aShow = true
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/queryApplyJoinTeamList', {
        userId: this.userId,
        teamId: this.myTeam2.teamId
      }).then((res) => {
        if (res.code == 1) {
          this.aList = res.data
        }
      })
    },
    agreeFun(item) {
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/passJoinTeam', {
        userId: this.userId,
        teamId: this.myTeam2.teamId,
        applyUserId: item.userId
      }).then((res) => {
        if (res.code == 1) {
          this.$toast('好友已成功加入你的战队')
        }
        this.showApply()
        this.getMyTeam()
      })
    },
    // 24-9-25
    goGift() {
      this.$router.push('/invite-gift');
    },
    getTask(type) {
      this.$axios.post(this.baseURLApp + '/schedule/teamActivity/listTaskProcessByUserId', {
        userId: this.userId,
        cityId: this.cityId2,
      }).then((res) => {
        this.taskList = res.data
        this.nowTask = res.data.filter(i => i.inProgress == 1)[0] || {}
        console.log(this.nowTask)
        this.taskShow = type
      })
    },
    finshTask(i, index) {
      if (i.inProgress == 1 && i.taskStatus == 1) {
        if (index == 0 && this.hasTeam1 == false) {
          this.$toast('先加入或创建战队')
        } else {
          gotoAppPage(3)
        }
      }
    },
    showTips(index) {
      // tipText=''
      this.tipIndex = index
      this.tipsShow = true
    },
  }
}
</script>

<style lang="less" scoped>
.page-content {
  background-color: #F5F5F5;
  font-weight: 400;
  min-height: 100vh;
  background-image: url(https://img.chaolu.com.cn/ACT/teamWarband-202308/title24-10.png?v=1);
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding-top: 700px;
  position: relative;
  box-sizing: border-box;


  .rule-btn,
  .gift-btn {
    position: absolute;
    right: 0px;
    top: 60px;
    width: 88px;
  }

  .gift-btn {
    top: 148px;
  }

  .city-btn {
    position: absolute;
    left: 32px;
    top: 60px;
    color: #fff;
    font-size: 28px;
    background-color: rgba(#000000, .2);
    padding: 12px 17px;
    border-radius: 10px;

    i {
      transform: translateY(-1px) rotate(90deg);
      margin-left: 5px;
    }
  }

  .top,
  .go-gift {
    position: fixed;
    right: 32px;
    bottom: 160px;
    width: 86px;
    height: 86px;
  }

  .go-gift {
    bottom: 780px;
    width: 125px;
    height: 143px;
    right: 0px;
  }


  .new-user {
    position: fixed;
    right: 0px;
    top: 728px;
    width: 136px;
    height: 136px;
  }

  .time {
    text-align: center;
    font-size: 26px;
    color: #fff;
    margin-top: -2px;
  }

  .total {
    display: flex;
    padding: 30px 32px;
    justify-content: space-between;
    line-height: 1;

    >div {
      width: 333px;
      padding: 33px 0px;
      text-align: center;
      font-size: 24px;
      color: #fff;
      border-radius: 24px;
      background-color: rgba(#fff, .16);

      p+p {
        margin-top: 20px;
        font-size: 30px;

        >i {
          font-size: 24px;
          margin-left: 18px;
        }
      }
    }

    .record {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .task-box {
    margin: 0px 32px 30px;
    background-color: rgba(#fff, .16);
    color: #fff;
    padding: 33px 40px;
    font-size: 28px;
    line-height: 1;
    border-radius: 24px;
    display: flex;
    align-items: center;

    .task {
      display: flex;
      flex-direction: column;
      flex: 1;

      .status {
        display: inline-block;
        background-color: #6F91FF;
        font-size: 20px;
        line-height: 30px;
        height: 30px;
        padding: 0px 12px;
        border-radius: 6px;
        margin-left: 12px;
        transform: translateY(-2px);
      }

      .rtime {
        font-size: 22px;
        margin-top: 20px;
        font-weight: 400;
      }
    }
  }

  .tab {
    &Img1 {
      background-image: url(https://img.chaolu.com.cn/ACT/teamWarband-202308/tab24-10-1.png);
    }

    &Img2 {
      background-image: url(https://img.chaolu.com.cn/ACT/teamWarband-202308/tab24-10-2.png);
    }

    background-size: 100% auto;
    display: flex;
    align-items: center;
    height: 110px;
    font-size: 30px;
    font-weight: 600;
    margin: 0px 32px;


    >span {
      width: 50%;
      text-align: center;
      position: relative;

      i {
        transform: rotate(90deg);
        margin-left: 16px;
      }

      .btn-mask {
        width: 100%;
        height: 110px;
        background-color: yellow;
        position: absolute;
        left: 0px;
        top: -34px;
        z-index: 30;
      }
    }
  }

  .list-content {
    padding: 0px 32px 104px;

    .rank,
    .my-team {
      background-color: #fff;
      padding: 38px 24px;
      border-radius: 0px 0px 24px 24px;
    }

    .my-rank {
      display: flex;
      align-items: center;
      height: 134px;
      background-color: #FFF2EF;
      border: 1px solid #F24C2A;
      border-radius: 20px;
      color: #F24C2A;
      font-size: 28px;
      font-weight: 500;
      padding-right: 28px;

      &.not-in {
        padding-left: 37px;
        padding-right: 24px;
        font-size: 26px;
        font-weight: 400;
        justify-content: space-between;

        .creat-btn {
          display: inline-block;
          text-align: center;
          width: 166px;
          height: 54px;
          line-height: 54px;
          font-size: 24px;
          font-weight: 600;
          background-color: #fff;
          box-shadow: 0px 0px 4px 0px rgba(89, 145, 255, 0.2);
          border-radius: 27px;
        }
      }

      >span {
        flex-shrink: 0;
      }

      .mc {
        font-size: 30px;
        width: 86px;
        text-align: center;
        margin-right: 10px;
      }

      .avt {
        width: 90px;
        height: 90px;

        >img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

    }

    .rank {
      .rank-item {
        margin-top: 44px;
        height: 90px;
        display: flex;
        align-items: center;
        padding-right: 44px;
        font-size: 22px;

        .mc {
          font-size: 34px;
          width: 86px;
          text-align: center;
          margin-right: 10px;
          color: #7C849B;

          >img {
            width: 36px;
          }
        }

        >span {
          flex-shrink: 0;
        }

        .avt {
          width: 90px;
          height: 90px;

          >img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }

    }

    .my-team {
      // height: 786px;
      position: relative;
      box-sizing: border-box;
      padding: 22px 24px 28px;

      &.dis {
        height: 660px;
      }

      .empty-box {
        text-align: center;
        font-size: 28px;
        color: #6C727A;

        img {
          width: 320px;
          height: 320px;
          margin-top: 100px;
        }

        .c-btn {
          margin-top: 137px;
          width: 638px;
          height: 90px;
          line-height: 90px;
          left: 24px;
          border-radius: 20px;
          color: #fff;
          font-size: 30px;
          font-weight: 600;
          background: linear-gradient(180deg, #FF8F78 0%, #F24C2A 100%);
        }
      }

      .t-detail {
        .t-icon {
          display: flex;
          justify-content: flex-end;
          font-size: 24px;
          color: #3C454E;
          padding-right: 30px;

          img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            margin-left: 42px;
          }
        }
      }

      .team-user {
        display: flex;
        flex-wrap: wrap;
        margin-top: 26px;

        >div {
          width: 20%;
          margin-bottom: 15px;
          text-align: center;
        }

        .tag {
          height: 36px;

          >img {
            height: 36px;
          }
        }

        .user-box {
          margin-top: 4px;
          display: flex;
          // justify-content: center;
          flex-direction: column;
          align-items: center;

          >.user {
            img {
              width: 90px;
              height: 90px;
              border-radius: 50%;
            }

            >.count {
              position: relative;
              bottom: 26px;
              display: inline-block;
              font-size: 20px;
              min-width: 72px;
              height: 28px;
              box-sizing: border-box;
              padding-top: 1px;
              color: #fff;
              background-color: #FF856C;
              border-radius: 28px;
            }
          }

          >.user-empty {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background-color: #FFE4DE;
            color: #F24C2A;
            text-align: center;
            line-height: 90px;
            font-size: 40px;
          }
        }

        .name {
          font-size: 22px;
          font-weight: 500;
          margin-top: -20px;
        }
      }

      .add-record {
        text-align: center;
        font-size: 24px;
        color: #242831;
        // margin-bottom: 32px;

        .zero,
        .has-user {
          margin-top: 32px;
          height: 64px;
          line-height: 64px;
          display: inline-block;
          padding: 0px 40px;
          border-radius: 60px;
        }

        .zero {
          background-color: rgba(36, 40, 49, 0.06);
        }

        .has-user {
          background-color: rgba(240, 60, 24, 0.12);

          .num {
            color: #fff;
            background-color: #F03C18;
            padding: 4px 10px;
            border-radius: 100px;
            margin-right: 8px;
          }
        }
      }

      .btn-box {
        display: flex;
        justify-content: space-between;

        .call-btn,
        .invite {
          flex: 1;
          text-align: center;
          height: 90px;
          line-height: 90px;
          border-radius: 20px;
          font-size: 30px;
          font-weight: 600;
          margin-top: 40px;
        }

        .call-btn {
          background-color: #FFF2EF;
          color: #F24C2A;
        }

        .mr18 {
          margin-right: 18px;
        }

        .invite {
          color: #fff;
          background: linear-gradient(180deg, #FF8F78 0%, #F24C2A 100%);
        }
      }
    }

    .call-box {
      background-color: #FFF2EF;
      border-radius: 20px 20px 20px 20px;

      .call {
        display: flex;
        align-items: center;
        height: 94px;
        font-size: 26px;
        padding: 0px 28px;


        .icon {
          width: 48px;
          height: 48px;
        }

        .avt {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

    }
  }
}

.rule-title {
  height: 140px;
  background-image: linear-gradient(180deg, #FFF2EF, #FFFFFF);
  text-align: center;
  line-height: 140px;
  font-size: 32px;
  font-weight: 600;
}

.rule-box {
  font-size: 26px;
  padding: 0px 52px 40px;
  list-style: 1.5;
  height: 60vh;
  overflow: scroll;
  white-space: pre-wrap;
  text-align: justify;
}

.gift-box {
  width: 100%;
  height: 60vh;
  overflow-y: scroll;
  text-align: center;
  padding-bottom: 80px;
  position: relative;

  .cfj {
    position: absolute;
    right: 195px;
    top: 270px;
    width: 100px;
    height: 190px;
  }

  .gift {
    width: 650px;
    height: auto;
  }
}


.pop-radius {
  border-radius: 24px 24px 0px 0px;

  &.close {
    /deep/.van-popup__close-icon--top-right {
      top: 44px;
    }
  }

}

.box-title>.title {
  height: 98px;
  line-height: 108px;
  font-size: 32px;
  font-weight: 600;
  border-bottom: 2px solid #F5F5F5;
  text-align: center;
}

.c-box {
  text-align: center;

  .avt {
    width: 180px;
    height: 180px;
    margin: 96px 0px 142px;
    border-radius: 50%;
  }

  .input {
    width: 686px;
    height: 100px;
    background-color: #F5F5F5;
    border-radius: 8px;
    overflow: hidden;
    margin: 0px auto 170px;
    padding: 0px 33px;

    &.focus {
      margin-bottom: 33vh;
    }

    /deep/.van-cell__value {
      display: flex;
      align-items: center;

      .van-field__body {
        width: 90%;
        height: 100%;

        input {
          height: 100%;
        }
      }
    }
  }

  .create-btn {
    padding: 34px 32px;
    border-top: 1px solid #eee;
    height: 160px;
    box-sizing: border-box;

    p {
      height: 92px;
      background-color: #FFDE00;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.t-box {

  .line {
    display: flex;
    margin: 32px;
    background-color: #F5F5F5;
    border-radius: 8px;
    box-sizing: border-box;
    align-items: center;

    &.no-chose {
      padding: 26px;
      font-size: 28px;
      line-height: 1;
      justify-content: space-between;
      color: #9AA1A9;

      .t-icon {
        width: 48px;
        height: 48px;
      }
    }

    &.chose {
      padding: 28px;
      font-size: 28px;
      font-weight: 600;

      .left {
        flex: 1;
        display: flex;
        align-items: center;

        .avt {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          margin-right: 24px;
        }

        .change {
          width: 88px;
          height: 32px;
          margin-right: 76px;
          border-radius: 40px;
          margin-left: 20px;
        }
      }

      .close {
        width: 32px;
        height: 32px;
      }
    }
  }

  .create-btn {
    padding: 34px 32px;
    height: 160px;
    box-sizing: border-box;
    border-top: 1px solid #EEEEEE;

    p {
      height: 92px;
      background-color: #FFDE00;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.a-box {
  height: 1000px;

  .list {
    background-color: #F5F5F5;
    padding: 24px;
    box-sizing: border-box;
    height: 900px;
    overflow-y: scroll;

    .item {
      display: flex;
      align-items: center;
      padding: 28px 24px;
      border-radius: 16px;
      background: #fff;
      box-sizing: border-box;
      margin-bottom: 24px;
      font-size: 22px;

      .avt {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        margin-right: 24px;
      }

      .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-right: 20px;

        .name {
          font-size: 28px;
          font-weight: 600;
        }

        .text {
          margin-top: 6px;
          margin-bottom: 6px;
          color: #3C454E;
        }

        .status {
          display: flex;
          align-items: center;
          font-size: 22px;
          color: #F03C18;

          .time {
            font-size: 22px;
            color: #F03C18;
            margin-right: 2px;
          }
        }

        .c1 {
          color: #267DFF;
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            margin-right: 6px;
          }
        }

        .c2 {
          color: #9AA1A9;
        }


      }

      .btn {
        flex-shrink: 0;
        width: 120px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        background-color: #FFDE00;
        border-radius: 4px;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .empty-box {
      text-align: center;
      font-size: 28px;
      color: #6C727A;

      img {
        width: 320px;
        height: 320px;
        margin-top: 100px;
      }
    }
  }


}

.q-box {
  width: 590px;
  height: 402px;
  text-align: center;
  padding: 50px 30px;
  box-sizing: border-box;
  font-size: 24px;
  color: #3C454E;
  border-radius: 12px;

  .q-btn {
    display: flex;
    justify-content: space-between;
    margin-top: 54px;

    >span {
      width: 244px;
      height: 96px;
      line-height: 96px;
      border-radius: 8px;
      font-size: 32px;
      border: 1px solid #D9D9D9;

      &.btn1 {
        background-color: #FFDE00;
        border-color: #FFDE00;
        font-weight: 600;
      }
    }
  }
}

.j-box {
  width: 590px;
  // height: 672px;
  border-radius: 40px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFF5EF 100%);
  text-align: center;
  overflow-y: visible;
  padding-bottom: 40px;

  &.hei {
    height: 718px;
  }

  .title {
    margin-top: 60px;
    height: 44px;
  }

  .avt {
    width: 122px;
    height: 122px;
    margin: 58px auto 23px;
    border-radius: 50%;
    display: block;
  }

  .jion-btn {
    width: 470px;
    height: 90px;
    background: linear-gradient(180deg, #FFEA00 0%, #FFD302 100%);
    box-shadow: 0px 6px 18px 0px rgba(255, 255, 255, 0.6);
    border-radius: 45px;
    font-size: 32px;
    font-weight: 600;
    line-height: 90px;
    margin: 50px auto 0px;

    &.dis {
      background: #EEEEEE;
      color: #9AA1A9;
    }
  }

  .tips {
    color: #F03C18;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 14px;

    .time {
      color: #F03C18;
      font-size: 24px;
      margin-right: 5px;
      margin-top: 4px;
    }
  }

  .close {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -120px;
    transform: translateX(-50%);
  }
}

.f-box {
  width: 590px;
  max-height: 909px;
  border-radius: 20px;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFF5EF 100%);
  text-align: center;
  padding: 40px 45px 34px;
  box-sizing: border-box;

  .t-detail {
    width: 500px;
    height: 134px;
    border: 1px solid #F24C2A;
    background-color: #FFF2EF;
    border-radius: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 22px;
    font-weight: 600;
    margin-top: 38px;

    .avt {
      width: 90px;
      height: 90px;
      margin-right: 24px;
      border-radius: 50%;
    }

    .mc {
      margin-left: 60px;
      font-size: 42px;
      color: #F24C2A;
    }
  }

  .gx {
    width: 117px;
    height: 32px;
    margin: 36px auto 10px;
  }

  .detail {
    width: 500px;
    max-height: 350px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #F24C2A;
    border-radius: 16px;
    margin: 30px auto 30px;
    background-color: #FFF2EF;

    .title {
      font-size: 24px;
      color: #fff;
      background-color: #F24C2A;
      width: 190px;
      height: 50px;
      line-height: 50px;
      margin: 0 auto;
      border-radius: 0px 0px 16px 16px;
    }

    .d-box {
      max-height: 300px;
      overflow: scroll;

      .detail-item {
        padding: 20px 32px 0px;
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 18px;
        }

        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }

  .f-btn {
    width: 500px;
    height: 90px;
    line-height: 90px;
    font-size: 36px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFEA00 0%, #FFD302 100%);
    box-shadow: 0px 6px 18px 0px rgba(255, 255, 255, 0.6);
    border-radius: 45px;
  }
}

.task-pop {
  background-color: #F5F5F5;

  .title {
    padding: 38px 0px;
    line-height: 1;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
  }

  .tips {
    font-size: 24px;
    line-height: 1;
    color: #F03C18;
    text-align: center;
  }

  .list {
    padding: 32px;

    .item {
      padding: 35px;
      background-color: #fff;
      border-radius: 16px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .content {
        flex: 1;
        font-size: 24px;
        color: #242831;
        font-weight: 400;
        display: flex;
        flex-direction: column;

        .l1 {
          font-size: 28px;
          font-weight: 600;
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .status {
            font-size: 20px;
            font-weight: 400;
            line-height: 1;
            padding: 6px 12px;
            border-radius: 6px;
            background-color: #6F91FF;
            color: #fff;
            margin-left: 12px;
          }
        }

        .icon {
          color: #888888;
        }
      }

      .btn {
        background-color: #F24C2A;
        color: #fff;
        border-radius: 10px;
        font-size: 22px;
        line-height: 1;
        padding: 15px 28px;
        font-weight: 600;

        &.not {
          opacity: .6;
        }
      }
    }
  }
}

.r-radius {
  border-radius: 12px;
}

.r-info-pop {
  padding: 48px 40px;
  width: 600px;
  box-sizing: border-box;

  .ri-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    margin-bottom: 52px;
  }

  .ri-content {
    font-size: 26px;
    line-height: 1.6;
  }

  .btn {
    width: 100%;
    height: 90px;
    line-height: 90px;
    background: #FFDE00;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin-top: 55px;
    border-radius: 8px;
  }
}

.no-anim {
  animation: none !important;
  transition: none !important;
}

@bei: 1px;

.canvascss {
  position: fixed;
  bottom: -200%;
  // top: 0px;
  width: @bei*654;
  height: @bei*1160;
  margin: 0 auto;
  box-sizing: border-box;

  .canvascss_bg {
    width: 100%;
    height: 100%;
  }

  .canvascss_left {
    position: absolute;
    left: @bei*32;
    bottom: @bei*36;
    z-index: 1;
    width: @bei*100;
    height: @bei*100;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .canvascss_name {
    position: absolute;
    left: @bei*160;
    bottom: @bei*118;
    z-index: 1;
    color: #fff;
    font-weight: bold;
    font-size: @bei*28;
    width: @bei*300;
  }

  .canvascss_right {
    position: absolute;
    right: @bei*32;
    bottom: @bei*19;
    width: @bei*140;
    height: @bei*140;
    border-radius: @bei*14;
    overflow: hidden;
    padding: @bei*10;
    box-sizing: border-box;

    img {
      width: @bei*110;
      height: @bei*110;
    }
  }
}
</style>
