<template>
	<van-popup v-model="show" position="bottom" safe-area-inset-bottom class="pop">
		<div class="pop-title">
			<span class="ts">选择你要打call的教练</span>
			<van-icon name="cross" class="pop-close" color="#fff" @click="show = false" />
		</div>
		<div class="pop-tuijian">
			<div class="flex p32">
				<div class="search-box">
					<img alt=""
						src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/search.png"
						class="search-icon" />
					<input type="search" ref="sh" v-model.trim="searchName" class="ipt" maxlength="10" placeholder="输入教练的名字" />
				</div>
				<div class="search-btn" @click="search">搜索</div>
			</div>
			<div class="tabbox" v-if="hasSearchData">
				<template v-if="teacherList.length">
					<div class="voucher-list">
						<div class="voucher-model" v-for="(item, index) in teacherList" :key="index">
							<img class="avt" :src="item.pic" alt />
							<span class="name">{{ item.name }}</span>
							<span class="btn" @click="chose(item)">选择TA</span>
						</div>
					</div>
				</template>
			</div>
			<div v-else>
				<img class="n1"
					src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/group-invite-202304/none.png" alt />
				<div class="n2">暂无匹配的教练</div>
			</div>
		</div>
	</van-popup>
</template>
<script>
import userMixin from '@/mixin/userMixin';
export default {
	mixins: [userMixin],
	data() {
		return {
			show: false,
			searchName: '',//搜索教练
			hasSearchData: true,
			teacherList: [],//搜索结果
		}
	},
	watch: {
		show(v) {
			if (v) {
				this.searchName = '';
				this.teacherList = [];
				this.hasSearchData = true;
				this.$nextTick(() => {
					this.$refs.sh.focus();
				})
			}
		}
	},
	methods: {
		search() {
			this.$toast.loading({
				duration: 0,
				forbidClick: true,
				loadingType: 'spinner',
			})
			if (!this.searchName) {
				this.$toast('请输入教练名字');
				return;
			}
			this.$axios
				.post(this.baseURLApp + "/feign/teacher/getTeacherInfoList", {
					cityId: '3',
					name: this.searchName,
					groupClass: 1,
					workStatus:1
				})
				.then((res) => {
					this.$toast.clear();
					console.log(res);
					this.hasSearchData = res.data.length > 0;
					this.teacherList = res.data;
				})
		},
		chose(item) {
			this.$emit('setShareTeacher', { id: item.id, name: item.name, pic: item.pic });
			this.show = false;
		}
	},
	created() {

	}
}
</script>
<style lang="less" scoped>
.pop {
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;

	.pop-title {
		color: #242831;
		font-size: 32px;
		font-weight: bold;
		text-align: center;
		height: 108px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #EEE;

		.pop-close {
			position: absolute;
			right: 32px;
			top: 38px;
			width: 44px;
			height: 44px;
			line-height: 44px;
			border-radius: 50%;
			background: rgba(39, 40, 49, 0.2);
			font-size: 28px;
			text-align: center;
		}
	}

}

.pop-tuijian {
	height: 908px;
	overflow: auto;

	.p32 {
		padding: 32px;
	}

	.search-box {
		flex: 1;
		border-radius: 8px;
		background: #F5F5F5;
		height: 80px;
		display: flex;
		align-items: center;
		margin-right: 28px;
		padding-left: 16px;
		box-sizing: border-box;

		input[type="search"] {
			-webkit-appearance: none;
		}

		input::-webkit-search-cancel-button {
			display: none;
		}

		.search-icon {
			width: 40px;
			height: 40px;
			margin-right: 10px;
		}

		.ipt {
			border: none;
			background: transparent;
			font-size: 30px;
			width: 100%;
			height: 80px;
		}
	}

	.search-btn {
		width: 116px;
		height: 76px;
		margin-top: 2px;
		background-color: #FFDE00;
		border-radius: 8px;
		font-size: 28px;
		line-height: 76px;
		font-weight: bold;
		text-align: center;
	}

	.voucher-list {
		height: 760px;
		overflow-y: scroll;
	}

	.voucher-model {
		display: flex;
		align-items: center;
		padding: 28px 32px;
		font-weight: bold;

		.avt {
			width: 96px;
			height: 96px;
			border-radius: 50%;
			margin-right: 24px;
		}

		.name {
			font-size: 28px;
			flex: 1;
		}

		.btn {
			width: 136px;
			height: 56px;
			line-height: 56px;
			background-color: #FFDE00;
			border-radius: 4px;
			font-size: 24px;
			text-align: center;
		}
	}

	.n1 {
		width: 120px;
		height: 120px;
		display: block;
		margin: 70px auto 32px;
	}

	.n2 {
		font-size: 26px;
		color: #242831;
		text-align: center;
	}
}</style>
